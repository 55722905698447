<template>
  <v-row class="full">
    <v-col class="py-16 px-16 py-lg-16 px-lg-10 ml-lg-16 align-content-center">
      <v-row>
        <v-col cols="12" md="6" class="mt-md-10 mb-md-16">
          <v-img src="../assets/logo-full.svg" alt="MP Administration" width="100%" max-width="370px"></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="8" lg="6" class="mt-8 mt-md-10 mt-lg-16">
          <h2>Mot de passe oublié</h2>
          <p>Merci de renseigner votre e-mail pour recevoir un lien de changement de mot de passe.</p>
          <v-form>
            <v-row>
              <v-col>
                <v-text-field
                  label="Email"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pt-8">
                <v-btn
                  class="white--text"
                  elevation="2"
                  large
                  rounded
                  color="blue"
                >Réinitialiser mot de passe</v-btn>
              </v-col>
              <v-col cols="12 pt-0">
                <v-btn tag="a"
                       href="/login"
                       rounded
                >Me connecter</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="3" lg="4" class="xs-absolute py-md-16 pl-md-16 blue elevation-9">

    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    form: {
    },
    rules: {
      required: value => !!value || 'Obligatoire.',
      emailMatch: () => ('The email and password you entered don\'t match')
    }
  })
}
</script>

<style lang="scss">
.full {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.col {
  position: relative;
}
</style>
